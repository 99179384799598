import React from "react"
import { graphql } from "gatsby"

import Hero from "../components/hero"
import Bio from "../components/bio"
import BookCapture from "../components/book-capture"
import MainLayout from "../components/main-layout"
import SEO from "../components/seo"
import SwirlBand from "../components/swirl-band"
import FeaturedVideos from "../components/featured-videos"

class SiteIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <MainLayout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <Hero />
        <SwirlBand />
        <Bio />
        <FeaturedVideos />
        <BookCapture />
      </MainLayout>
    )
  }
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
