import React from "react"

const SwirlBand = ({ flipped = false }) => {
  return (
    <img
      className={`h-auto w-full bottom-0 z-10 relative ${
        flipped ? "transform rotate-180 -mt-1" : "-mb-1"
      }`}
      src="/swirl.svg"
      alt="Joyful Noise Learning Homeschool resources"
    />
  )
}

export default SwirlBand
