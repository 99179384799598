import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { format, parse } from "date-fns"
import _ from "lodash"

const getYouTubeLinkId = link => {
  let parts = link.split("/")
  return parts[parts.length - 1]
}

const Video = ({ youTubeLink, publishDate, title, subtitle }) => {
  let youTubeLinkId = getYouTubeLinkId(youTubeLink)

  return (
    <a
      href={`https://youtu.be/${youTubeLinkId}`}
      target="_blank"
      rel="noopener noreferrer"
      className="w-full md:w-1/3 px-4 mb-4"
    >
      <div className="bg-white rounded shadow-xl overflow-hidden">
        {youTubeLinkId ? (
          <img
            className="w-full h-56 object-cover"
            src={`https://img.youtube.com/vi/${youTubeLinkId}/0.jpg`}
            alt={title}
          />
        ) : null}

        <div className="h-64 lg:h-48 flex flex-col justify-start py-4">
          <div className="text-gray-600 font-bold px-6 mt-2 truncate break-words">
            {title}
          </div>
          <div className="text-xs md:text-sm px-6 mb-1">
            <span className="text-sm font-thin tracking-wider text-gray-400">
              {publishDate}
            </span>
          </div>
          <div className="text-gray-500 text-sm md:text-sm px-6 mb-2">
            {_.truncate(subtitle, { length: 120, separator: " " })}
          </div>
        </div>
      </div>
    </a>
  )
}

const FeaturedVideos = () => {
  const data = useStaticQuery(graphql`
    query {
      contentful: allContentfulFeaturedVideos {
        videos: nodes {
          title
          subtitle
          youTubeEmbed {
            youTubeEmbed
          }
          publishDate
          youTubeLink
        }
      }
    }
  `)

  let videos = data.contentful.videos || []

  return (
    <section className="bg-gray-100">
      <div className="container mx-auto px-6 py-20">
        <div className="py-4 mb-6">
          <div className="border-gray-500 border-b">
            <h2 className="inline-block text-4xl font-bold tracking-wide text-gray-700">
              Homeschool Help
            </h2>
            <h3 className="text-2xl font-thin text-gray-500">
              YouTube Curriculum Reviews &amp; More
            </h3>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap mb-12">
            {videos.map((video, i) => {
              let published = format(
                parse(video.publishDate, "yyyy-MM-dd", new Date()),
                "M/dd/yyyy"
              )
              return <Video published={published} {...video} key={i} />
            })}
          </div>
          <div className="text-center text-white py-6">
            <div>
              <a
                href="https://www.youtube.com/c/joyfulnoiseliving"
                className="text-gray-700 hover:text-gray-700 bg-yellow-200
            hover:bg-yellow-300 rounded-full py-4 px-8 shadow-lg"
              >
                <svg
                  className="inline fill-current h-8 w-8 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                >
                  <path d="M508.64,148.79c0-45-33.1-81.2-74-81.2C379.24,65,322.74,64,265,64H247c-57.6,0-114.2,1-169.6,3.6-40.8,0-73.9,36.4-73.9,81.4C1,184.59-.06,220.19,0,255.79q-.15,53.4,3.4,106.9c0,45,33.1,81.5,73.9,81.5,58.2,2.7,117.9,3.9,178.6,3.8q91.2.3,178.6-3.8c40.9,0,74-36.5,74-81.5,2.4-35.7,3.5-71.3,3.4-107Q512.24,202.29,508.64,148.79ZM207,353.89V157.39l145,98.2Z" />
                </svg>
                Joyful Noise Learning on YouTube
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedVideos
