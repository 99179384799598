import React, { useEffect } from "react"

const Capture = ({ uid = "5cd2c298d5" }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = `https://joyful-noise-learning.ck.page/${uid}/index.js`
    script.async = true
    script.dataset.uid = uid

    document.getElementById(`capture-${uid}`).appendChild(script)
  }, [uid])

  return <div id={`capture-${uid}`}></div>
}

export default Capture
