import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import PlainMark from "./icons/plain-mark"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      devices: file(
        relativePath: { eq: "images/ready-set-homeschool-device-shots.png" }
      ) {
        image: childImageSharp {
          fixed(width: 600) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="h-auto w-full relative">
      <PlainMark className="absolute w-2/4 right-0 top-0 -mr-32 mt-48 opacity-25 text-jnl-teal-medium z-0 " />
      <PlainMark className="absolute w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
      <PlainMark className="absolute w-24 left-0 top-0 sm:ml-96 lg:ml-32 sm:mt-96 lg:mt-48 opacity-25 text-jnl-red-medium z-0 " />

      <div
        className="container pt-16 md:pb-48 mx-auto h-full 
                   flex flex-wrap sm:flex-col
                   md:flex-row lg:py-32 md:py-32 z-20"
      >
        <div className="w-full px-8 lg:px-8 lg:w-1/2 z-20">
          <h1>
            <div className="text-3xl md:text-5xl lg:text-4xl text-jnl-teal-medium mb-2 sm:mb-2 md:mb-4">
              Ready, Set,
            </div>
            <div className="text-4xl md:text-6xl lg:text-6xl text-jnl-red-medium leading-4 lg:leading-8 mb-8">
              Homeschool
            </div>
          </h1>
          <h2 className="mt-4 mb-10 text-xl">
            A Step by Step Guide to Start Homeschooling
          </h2>
          <Link
            to="/ready-set-homeschool"
            className={`bg-yellow-200 md:text-xl lg:text-xl text-gray-700 font-bold uppercase
              rounded-full pt-4 pb-4 px-8 shadow-lg lg:tracking-wider leading-loose hover:bg-yellow-100
              hover:shadow-xl hover:text-gray-600 border-white border-2 `}
          >
            I Want This eBook!
          </Link>
        </div>

        <div className="w-full lg:w-1/2 flex justify-center lg:relative mt-4">
          <div className="w-full lg:absolute sm:-mb-64 lg:-mt-48 lg:top-0">
            <Image
              fixed={data.devices.image.fixed}
              alt="Ready Set Homeschool eBook"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
