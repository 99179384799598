import React from "react"
import Image from "gatsby-image"
import PageHeader from "./page-header"
import { useStaticQuery, graphql } from "gatsby"
import Capture from "./blog/capture"
// import background from "../../content/assets/images/papers.jpg"

const BookCapture = () => {
  const data = useStaticQuery(graphql`
    query {
      printable: file(relativePath: { eq: "images/best-resources-block.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // useEffect(() => {
  //   const script = document.createElement("script")

  //   script.src = "https://joyful-noise-learning.ck.page/5cd2c298d5/index.js"
  //   script.async = true
  //   script.dataset.uid = "5cd2c298d5"

  //   document.getElementById("book-capture").appendChild(script)
  // }, [])

  let printableImg = data.printable.childImageSharp.fixed

  return (
    <section className="bg-white min-h-96 pt-12">
      <div className="container mx-auto px-6 py-8">
        <PageHeader>New to Homeschooling Preschool?</PageHeader>
        <div className="flex flex-wrap">
          <div className="md:w-1/2 space-y-4">
            <p>
              I've gathered a list of my{" "}
              <strong className="text-jnl-red-medium">FAVORITE</strong>{" "}
              resources for preschool at home.
            </p>
            <p>Enter your email address and I'll send you:</p>
            <ul className="list-disc list-inside">
              <li>Affordable curriculum ideas</li>
              <li>Books to read to prepare to homeschool</li>
              <li>The best podcasts and blog posts to encourage you</li>
            </ul>

            <Image
              fixed={printableImg}
              className="inline-block w-48 h-48 mb-8"
              alt="Best resources for preschool"
            />
          </div>
          <div className="md:w-1/2">
            <Capture uid="5cd2c298d5" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BookCapture
