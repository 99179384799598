import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "images/papers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avatar: file(relativePath: { eq: "images/ashley.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let ashley = data.avatar.childImageSharp.fluid

  return (
    <section className="bg-jnl-teal-light relative">
      <div className="container lg:flex md:justify-center mx-auto px-6 pt-12 pb-24">
        <div className="w-full md:w-1/4">
          <Image
            fluid={ashley}
            alt="Avatar of Ashley"
            className="w-64 h-64 md:w-48 md:h-48 rounded-full shadow-xl border-2 border-white mb-8 mx-auto"
          />
        </div>
        <div className="w-full lg:w-2/4 space-y-6 px-8">
          <h3 className="text-3xl text-gray-100">Hi, I’m Ashley!</h3>
          <p>
            Before I started homeschooling I wanted someone to hold my hand and
            just tell me what to do. After reading many books, listening to
            copious amounts of podcasts, and interviewing many a homeschool mom
            in my local church, I finally felt prepared to homeschool my kids
            well.
          </p>
          <p>
            I have homeschooled 3 kids from preschool through late elementary
            and I am loving it! I have enjoyed researching the different types
            of curriculums and styles of homeschooling, and now would love to
            educate you and hold your hand on getting started with homeschool.
          </p>
          <p>
            I’m happy to share with you some amazing resources that are
            available across the web so you, too, can be prepared to homeschool.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Bio
